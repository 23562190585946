let key = {};
if (process.env.REACT_APP_MODE === "production") {
  // const API_URL = "https://dopamineapi.wealwin.com"; //Demo
  //   const API_URL = "https://api.satxswap.com"; //https://api.dopaminenft.io
  // key = {
  //   baseUrl: `${API_URL}`,
  //   singlesmartContract: "0xc0eCb515891f21628F8e4B7696241B7A59A3CEE7",
  //   multiplesmartContract: "0x28D9E10E464ed5AB1F4fb28e1B2B909bC928896d",
  //   network: 1,
  // };

  const API_URL = "https://nftapi.metadiacdemo.com"; //https://api.dopaminenft.io
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x7841DA2CA7cBA260562dC6Ec73d6b9a9354B3731",
    multiplesmartContract: "0xdDCC6B7bE1cAD5A320dfb3730e22250Ed4D7a598",
    network: 97
  };
} else {
  //Demo
  const API_URL = "http://localhost:2053";
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x7841DA2CA7cBA260562dC6Ec73d6b9a9354B3731",
    multiplesmartContract: "0xdDCC6B7bE1cAD5A320dfb3730e22250Ed4D7a598",
    network: 5
  };
}

export default key;
